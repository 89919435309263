<template>
  <div id="userList">
    <el-row class="header-row">
      <el-col :span="24">
        <h3 class="el-page-header">User Admin</h3>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/user-admin' }">Users</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/user-admin' }">User Detail</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
      <div v-if="initializing" v-loading="initializing" class="top-margin" element-loading-spinner="atom-audit-loader" style="min-height: 150px;"></div>
      <div v-if="!initializing" v-loading="loading" element-loading-spinner="atom-audit-loader" style="min-height: 150px;">
        <el-form size="mini" label-position="left" label-width="200px">
          <el-row :gutter="40">
            <el-button v-if="isNew" :disabled="auth0FormConfirm || userAccessMessage" @click="createNewUser" class="hiaButton" plain round style="position:absolute; top: 0px; right: 15px; z-index: 1;"><i class="mdi mdi-account-multiple-plus coderIcon"></i>Create User </el-button>
            <el-col :span="12">
              <h4 class="heading">User Profile</h4>
              <el-form-item label="Full Name">
                <el-input v-model="fullName" size="mini" type="text" @change.native="putUser(loadedUser)"></el-input>
              </el-form-item>
              <el-form-item label="Email Address">
                <el-input v-if="!isNew" disabled v-model="loadedUser.email" size="mini" type="text" id="email">
                  <el-button
                    slot="append"
                    title="Update Email Address"
                    style="border-top-left-radius: 0px !important;
                    border-bottom-left-radius: 0px !important;"
                    icon="el-icon-edit"
                    size="mini"
                    @click="updateAuth0UserModal(loadedUser)"
                  ></el-button>
                </el-input>
                <template v-else>
                  <el-input v-model="newUserEmail" size="mini" type="text" v-on:keyup.native="newUserValidate()"> </el-input>
                  <div v-if="invalidemailMessage" style="padding: 0px 0px 20px 0px;">
                    <small style="color: #F56C6C; position: absolute; bottom: -2px; left: 0px;">{{ invalidemailMessage }}</small>
                  </div>
                </template>
              </el-form-item>
              <el-form-item v-if="!isNew" label="Invite/PW Reset Sent">
                <el-input disabled v-model="cronInvitationSentComputed" size="mini" type="text" id="invite"></el-input>
              </el-form-item>
              <el-form-item v-if="!isNew" label="Last Login">
                <el-input disabled v-model="lastLoginComputed" size="mini" type="text" id="lastLogin"></el-input>
              </el-form-item>

              <el-form-item size="mini" label="Linked Coder" v-if="rolesGranted.some(x => x.granted && x.roleId === 6)">
                <coderSelector v-model="loadedUser.coderId" @selected="handleCoderSelected" :clientcoder="loadedUser.clientCoder"> </coderSelector>
              </el-form-item>

              <el-row v-if="!isNew">
                <h4 class="heading">User Actions</h4>
                <el-form-item>
                  <template slot="label">
                    <el-tooltip :content="userActiveTooltip" placement="bottom" effect="light">
                      <span class="dottedUnderline">Active </span>
                    </el-tooltip>
                  </template>
                  <el-switch v-model="userTenantActive" active-color="#13ce66" @change="putUserActive(loadedUser)" inactive-color="#ff4949"> </el-switch>
                </el-form-item>
                <el-form-item>
                  <template slot="label">
                    <span>{{ loadedUser.lastLogin ? 'PW Reset Email' : 'Invitation Email' }} </span>
                  </template>
                  <el-button round icon="el-icon-message" :disabled="loading" @click="pwResetEmail(loadedUser)">Send Email </el-button>
                </el-form-item>

                <el-form-item label="PW Reset Link" v-if="ITUser()">
                  <el-button round icon="el-icon-link" :disabled="loading" @click="getPWTicket()">
                    Get Link
                  </el-button>
                  <div v-loading="pwTicketLoader" element-loading-spinner="atom-audit-loader">
                    <el-input v-if="loadedUser.pwResetTicket" disabled v-model="loadedUser.pwResetTicket" size="mini" type="text" id="pwResetTicket"></el-input>
                  </div>
                </el-form-item>
              </el-row>
            </el-col>
            <el-col :span="12">
              <el-row>
                <el-col :span="24">
                  <h4 class="heading">Roles</h4>
                  <el-alert v-if="userAccessMessage" type="warning" style="float: left; margin-bottom: 20px; font-size: 14px !important;" :closable="false">
                    A User must have at least 1 role.
                  </el-alert>
                </el-col>
                <el-col :span="24">
                  <el-form-item v-for="row in rolesGranted" :key="row.role" :label="row.role">
                    <template slot="label">
                      <el-tooltip popper-class="nucleusTooltip" :content="row.description" placement="bottom" effect="light">
                        <span class="dottedUnderline">{{ row.role }}</span>
                      </el-tooltip>
                    </template>
                    <el-switch v-model="row.granted" active-color="#13ce66" @change="addDeleteUserRole(loadedUser, row)" inactive-color="#ff4949"></el-switch>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <div v-if="!initializing" class="dialogs">
      <el-dialog v-loading="loading" title="Update Email Address" :visible.sync="showUpdateAuth0User" width="30%" :show-close="false" :close-on-click-modal="false">
        <el-form :model="auth0Form" ref="auth0Form" :rules="auth0Form.rules" size="mini" label-position="top" label-width="180px" :show-message="false">
          <el-form-item label="New Email Address" prop="email">
            <el-col :span="24">
              <el-input v-model="auth0Form.email" size="mini" type="text" v-on:keyup.native="updateAuth0FormValidate()"> </el-input>
              <span
                ><small style="color: #F56C6C; position: absolute; bottom: -30px; left: 0px;">{{ invalidemailMessage }}</small></span
              >
            </el-col>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="showUpdateAuth0User = false; auth0Form.email = ''; invalidemailMessage = ''; auth0Form.domain = '';">
            Cancel</el-button>
          <el-button :disabled="auth0FormConfirm" type="primary" @click="putAuth0User()">Confirm
          </el-button> -->
          <el-button-group>
            <el-button
              class="hiaButton"
              plain
              round
              size="mini"
              type="text"
              @click="
                showUpdateAuth0User = false
                auth0Form.email = ''
                invalidemailMessage = ''
                auth0Form.domain = ''
              "
              >Cancel</el-button
            >
            <el-button class="hiaButton" plain round size="mini" @click="putAuth0User()">Confirm</el-button>
          </el-button-group>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import { format, parseISO } from 'date-fns'
  import { mapState, mapActions, mapMutations } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import hiaFilters from '@/mixins/hiaFilters'
  import { createHelpers } from 'vuex-map-fields'
  import coderSelector from '@/components/controls/coderSelector'

  const { mapFields } = createHelpers({
    getterType: 'userAdmin/getField',
    mutationType: 'userAdmin/updateField'
  })

  export default {
    data: function() {
      return {
        loading: false,
        initializing: true,
        pwTicketLoader: false,
        showUpdateAuth0User: false,
        auth0FormConfirm: true,
        userActiveTooltip: "Grants or disables User's access",
        cronUserTooltip: 'User is required prior to selecting other Cron roles below. User grants access to Document Manager and Requests.',
        cronUserTooltipNew: 'New users will automatically receive a Cron Invitation email.',
        codingDashboardTooltip: 'Grants access to the Cron Coding Dashboard page.',
        reviewDashboardTooltip: 'Grants access to the Review Dashboard page.',
        cronUserAdminTooltip: "Members can create and manage other user's access to Cron",
        codingLogsTooltip: 'Members will receive daily Coding Logs emails from HIA coders.',
        codingScheduleTooltip: 'Members will receive Coding Schedule emails and will be copied on Cron Coding Schedule Requests.',
        reviewScheduleTooltip: 'Members will receive Review Schedule emails and will be copied on Cron Review Requests.',
        auth0Form: {
          email: '',
          domain: '',
          rules: {
            email: [{ required: true, trigger: 'change', type: 'email' }]
          }
        },
        invalidemailMessage: '',
        newContactRole: {
          clientNumber: null,
          userId: null,
          currentTenant: null,
          role: null,
          roleId: null,
          active: false
        },
        newUserEmail: '',
        newUserDomain: '',
        userAccessMessage: false,
        showOtherFacilityAccess: false,
        deleteContactRoles: [],
        filterCriteria: '',
        currentPage: 1,
        itemsPerPage: 10,
        facilityMultiSelect: []
      }
    },
    mixins: [HiaAuthorization, hiaFilters],
    name: 'UserAdmin',
    components: {
      coderSelector
    },
    methods: {
      ...mapActions('userAdmin/', ['GET_USERS', 'GET_USER', 'PUT_USER', 'PUT_USER_ACTIVE', 'GET_PW_RESET_TICKET', 'POST_PW_RESET_EMAIL', 'GET_ROLES', 'POST_USER_ROLE', 'DELETE_USER_ROLE', 'POST_USER']),
      ...mapMutations('userAdmin/', ['PUSH_USER_ROLE', 'SPLICE_USER_ROLE', 'SET_USER', 'SPLICE_NEW_USER_ROLE', 'SET_MASSUPDATE_USERROLES', 'SET_USER_EMAIL']),
      initialize() {
        // loadedUser admin check
        if (!this.userAdmin()) {
          this.$router.push('/')
          return
        }

        this.initializing = true
        if (!this.isNew) {
          Promise.all([this.GET_USER(this.$route.params.id), this.GET_ROLES()]).then(response => {
            this.initializing = false
          })
        } else {
          this.userAccessMessage = true
          Promise.all([this.GET_ROLES()]).then(response => {
            this.initializing = false
          })

          this.SET_USER({
            auth0UserId: null,
            usersRoles: [],
            email: null,
            fullName: null,
            cronInvitationSent: null,
            lastLogin: null,
            phoneNumber: null,
            sendInvitation: false,
            title: null,
            userTenantActive: true
          })
        }
      },
      async handleCoderSelected(e) {
        if (!e.coderId) {
          this.loadedUser.clientCoder = null
          await this.putUser(this.loadedUser)
          return
        }

        if (e.users && e.users.some(x => x.userId !== this.loadedUser.userId)) {
          this.$message({
            message: `Coder ${e.name} is already linked to a different user`,
            type: 'error'
          })
          return
        }

        this.loadedUser.clientCoder = e
        await this.putUser(this.loadedUser)
      },
      async createNewUser() {
        // set email domain
        this.loadedUser.email = `${this.newUserEmail}${this.newUserDomain}`.trim()
        this.loading = true

        try {
          await this.POST_USER(this.loadedUser)
          this.loading = false
          this.$message({
            message: 'User Created',
            type: 'success'
          })
          this.$router.push(`/user-admin/${this.loadedUser.userId}`)
        } catch (e) {
          this.$message({
            message: e.message,
            type: 'error'
          })
          this.loading = false
        }
      },
      async addDeleteUserRole(loadedUser, row) {
        if (this.loadedUser.auth0Id === this.user.auth0Id && row.roleId === 1) {
          try {
            await this.$confirm('Remove user admin from your own user record?', 'Warning', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
            })
          } catch (e) {
            // this.loadedUser.userTenantActive = !this.loadedUser.userTenantActive
            console.log(e)
            return
          }
        }

        const payload = {
          userRoleId: row.userRoleId,
          userId: loadedUser.userId,
          roleId: row.roleId
        }

        try {
          if (row.granted) {
            this.loadedUser.usersRoles.push(payload)
            this.userAccessMessage = false
            if (!this.isNew) {
              await this.POST_USER_ROLE(payload)
            }
          } else {
            const index = this.loadedUser.usersRoles.findIndex(x => x.roleId === row.roleId)
            this.loadedUser.usersRoles.splice(index, 1)
            if (!this.isNew) {
              await this.DELETE_USER_ROLE(payload)
            }
          }
          this.$message({
            message: 'User Roles Updated',
            type: 'success'
          })
        } catch (e) {
          this.$message({
            message: 'Error Updating User Roles: ' + e.message,
            type: 'error'
          })
          console.log(e.message)
          // console.log(e)
        }
      },
      async putUserActive() {
        if (this.loadedUser.auth0Id === this.user.auth0Id) {
          try {
            await this.$confirm('Change your own user record to inactive?', 'Warning', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
            })
          } catch (e) {
            this.loadedUser.userTenantActive = !this.loadedUser.userTenantActive
            console.log(e)
            return
          }
        }

        try {
          this.loading = true
          await this.PUT_USER_ACTIVE(this.loadedUser)
          this.loading = false
          this.$message({
            message: 'User Updated',
            type: 'success'
          })
        } catch (e) {
          this.$message({
            message: 'Error Updating User',
            type: 'error'
          })
          this.loading = false
          console.log(e)
        }
      },
      putUser(loadedUser) {
        if (this.isNew) {
          return
        }

        this.loading = true

        this.PUT_USER(this.loadedUser).then(
          response => {
            this.loading = false
            this.$message({
              message: 'User Updated',
              type: 'success'
            })
          },
          error => {
            this.$message({
              message: 'Error Updating User',
              type: 'error'
            })
            this.loading = false
            console.log(error)
          }
        )
      },
      pwResetEmail(loadedUser) {
        this.loading = true
        this.POST_PW_RESET_EMAIL(loadedUser).then(
          response => {
            this.loadedUser.invitationSent = response.invitationSent
            // this.cronInvitationSent = response.cronInvitationSent
            this.loading = false
            this.$message({
              message: 'Password reset email sent',
              type: 'info'
            })
          },
          error => {
            this.$message({
              message: 'Error Sending Email: ' + error.response.data.message,
              type: 'error'
            })
            this.loading = false
          }
        )
      },
      getPWTicket() {
        this.pwTicketLoader = true
        // call method with loadedUser.auth0UserId
        this.GET_PW_RESET_TICKET(this.loadedUser.auth0Id).then(response => {
          this.pwTicketLoader = false
        })
      },
      updateAuth0UserModal() {
        this.showUpdateAuth0User = true
      },
      updateAuth0FormValidate() {
        this.$refs.auth0Form.validate(valid => {
          if (valid && this.auth0Form.email.length) {
            if (!/[\\/:*?""<>|]/.test(this.auth0Form.email)) {
              this.invalidemailMessage = ''
              this.auth0FormConfirm = false
              return true
            } else {
              this.invalidemailMessage = 'Email Address may not contain any of \\ / : * ? "< > | 1'
              this.auth0FormConfirm = true
              return false
            }
          } else {
            this.invalidemailMessage = 'Email Address may not contain any of \\ / : * ? "< > | 2'
            this.auth0FormConfirm = true
            return false
          }
        })
      },
      newUserValidate() {
        if (this.newUserEmail.length && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.newUserEmail)) {
          if (!/[\\/:*?""<>|]/.test(this.newUserEmail)) {
            this.invalidemailMessage = ''
            this.auth0FormConfirm = false
            return true
          } else {
            this.invalidemailMessage = 'Email Address may not contain any of \\ / : * ? "< > |'
            this.auth0FormConfirm = true
            return false
          }
        } else {
          this.invalidemailMessage = 'Email Address may not contain any of \\ / : * ? "< > |'
          this.auth0FormConfirm = true
          return false
        }
      },
      putAuth0User() {
        this.$refs.auth0Form.validate(valid => {
          if (valid) {
            const payload = Object.assign({}, this.loadedUser)
            payload.email = this.auth0Form.email.trim()
            payload.updateEmail = true
            if (!this.loadedUser.email) {
              payload.sendInvitation = true
            }
            this.loading = true
            this.PUT_USER(payload).then(response => {
              this.loading = false
              this.showUpdateAuth0User = false
              this.SET_USER_EMAIL(payload.email)
              this.auth0Form.email = ''
              this.$message({
                message: 'User Updated',
                type: 'success'
              })
            })
          }
        })
      }
    },
    created: function() {
      this.initialize()
    },
    computed: {
      ...mapState('user/', ['user']),
      ...mapState('userAdmin/', ['users', 'loadedUser', 'roles', 'distinctDomains']),
      ...mapFields(['loadedUser.fullName', 'loadedUser.title', 'loadedUser.phoneNumber', 'loadedUser.cellNumber', 'loadedUser.userTenantActive', 'loadedUser.hiaTitle', 'loadedUser.notes', 'loadedUser.cronInvitationSent', 'loadedUser.pwResetTicket']),
      cronInvitationSentComputed() {
        if (!this.loadedUser.invitationSent) {
          return null
        }
        return format(parseISO(this.loadedUser.invitationSent), 'M/dd/yyyy h:mm a')
      },
      lastLoginComputed() {
        if (!this.loadedUser.lastLogin) {
          return null
        }
        return format(parseISO(this.loadedUser.lastLogin), 'M/dd/yyyy h:mm a')
      },
      isNew() {
        return this.$route.params.id === 'new'
      },
      rolesGranted() {
        return this.roles.map(x => {
          x.granted = this.loadedUser.usersRoles.some(y => y.roleId === x.roleId)
          return x
        })
      }
    }
  }
</script>

<style scoped>
  /* BUTTON ICONS*/
  .linkIcon {
    font-size: 20px;
    float: left;
    height: 12px;
    margin: -3px 5px 0px 0px;
  }

  .coderIcon {
    font-size: 20px;
    float: left;
    height: 12px;
    margin: -3px 5px 0px 0px;
  }

  >>> #rolesGrid .VuePagination__count {
    display: none;
  }

  >>> #rolesGrid thead {
    display: none;
  }
</style>

<style>
  .cronTooltip {
    width: 250px !important;
  }
</style>
