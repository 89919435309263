<template>
  <div id="userList">
    <el-row class="header-row">
      <el-col :span="24">
        <h3 class="el-page-header">User Admin</h3>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{path: '/' }">Home</el-breadcrumb-item>
          <el-breadcrumb-item :to="{path: '/user-admin' }">Users</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
      <div v-if="loading" v-loading="loading" class="top-margin" element-loading-spinner="atom-audit-loader" style="min-height: 150px;"></div>
      <div v-if="!loading">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-button @click="createNewUser" class="hiaButton" plain round size="mini"><i class="mdi mdi-account-multiple-plus coderIcon"></i>New User
            </el-button>
          </el-col>
          <el-col :span="12">
            <el-button style="float: right" @click="viewLicense" class="hiaButton" plain round size="mini"><i class="mdi mdi-license licenseIcon"></i>View License
            </el-button>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px;">
          <el-col :span="24">
            <v-client-table v-loading="loading" element-loading-spinner="atom-audit-loader" class="hiaTable" id="usersGrid" ref="usersGrid" :data="users" :columns="columns"
              :options="options">
              <template slot="fullName" slot-scope="props">
                <router-link :to="`user-admin/${props.row.userId}`">{{props.row.fullName}}</router-link>
              </template>
              <template slot="roles" slot-scope="props">
                <div class="el-tag el-tag--mini el-tag--light" style="max-width: 200px; " v-for="role in props.row.userRoles" :key="role.roleId" size="mini">
                  <div :title="role.role" style="overflow: hidden; text-overflow: ellipsis;">
                    {{role.role}}
                  </div>
                </div>
              </template>
              <template slot="active" slot-scope="props">
                <div style="text-align: center;">
                  <i :style="props.row.active ? 'color: #13ce66; font-size: 16px; font-weight: bold;' : 'color: #F56C6C; font-size: 16px; font-weight: bold;'"
                    :class="props.row.active ? 'el-icon-check' : 'el-icon-close'"></i>
                </div>
              </template>
              <template slot="invitationSent" slot-scope="props">
                {{props.row.invitationSent ? format(parseISO(props.row.invitationSent), 'M/dd/yyyy h:mm a') : null}}
              </template>
              <template slot="lastLogin" slot-scope="props">
                {{props.row.lastLogin ? format(parseISO(props.row.lastLogin), 'M/dd/yyyy h:mm a') : null}}
              </template>
            </v-client-table>

            <!-- <el-table id="userList2" :data="users" style="width: 100%; margin-top: 20px;">
            <el-table-column prop="fullName" label="Name" sortable>
              <template slot-scope="scope">
                <router-link :to="`user-admin/${scope.row.userId}`">{{scope.row.fullName}}</router-link>
              </template>
            </el-table-column>
            <el-table-column prop="email" label="Email Address" sortable></el-table-column>
            <el-table-column prop="title" label="Title" sortable></el-table-column>
            <el-table-column prop="notes" label="Notes" sortable></el-table-column>
            <el-table-column prop="inactive" label="Active" width="80px" sortable>
              <template slot-scope="scope">
                <div>
                  <i :style="!scope.row.inactive ? 'color: #13ce66; font-size: 16px; font-weight: bold;' : 'color: #F56C6C; font-size: 16px; font-weight: bold;'"
                    :class="!scope.row.inactive ? 'el-icon-check' : 'el-icon-close'"></i>
                </div>
              </template>
            </el-table-column>
          </el-table> -->

          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-dialog title="License Information" :visible.sync="showLicenseDetails" width="35%">
      <div v-if="licenseLoading" v-loading="licenseLoading" class="top-margin" element-loading-spinner="atom-audit-loader" style="min-height: 150px;"></div>
      <div v-if="!licenseLoading" v-loading="licenseLoading" class="top-margin" element-loading-spinner="atom-audit-loader" style="min-height: 150px;">
        <el-table :data="licenseTable" style="width: 100%">
          <el-table-column prop="type" label="Type" width="180">
          </el-table-column>
          <el-table-column prop="purchased" label="Purchased" width="180">
          </el-table-column>
          <el-table-column prop="used" label="Used">
          </el-table-column>
          <el-table-column prop="available" label="Available">
          </el-table-column>
        </el-table>
        <el-row :gutter="20" style="padding-top: 20px;">
          <el-col :span="12">
            <el-form size="mini" label-position="left" label-width="100px">
              <el-form-item label="Renewal Date">
                <el-input v-model="contractEndDate" size="mini" type="text" disabled></el-input>
              </el-form-item>
            </el-form>
          </el-col>

        </el-row>
      </div>
    </el-dialog>

  </div>
</template>

<script>

  import { mapState, mapActions } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import hiaFilters from '@/mixins/hiaFilters'
  import { format, parseISO } from 'date-fns'

  export default {
    data: function () {
      return {
        format: format,
        parseISO: parseISO,
        loading: true,
        filterCriteria: '',
        currentPage: 1,
        itemsPerPage: 10,
        isIE11: !!navigator.userAgent.match(/Trident\/7\./),
        columns: ['fullName', 'email', 'roles', 'invitationSent', 'lastLogin', 'active'],
        options: {
          headings: {
            fullName: 'Full Name',
            lastLogin: 'Last Login',
            invitationSent: 'Invite/PW Reset Sent'
          },
          filterAlgorithm: {
            roles(row, query) {
              return row.userRoles.some(x => x.role.toUpperCase().includes(query.toUpperCase()))
            },
            lastLogin(row, query) {
              if (row.lastLogin) {
                return format(parseISO(row.lastLogin), 'M/dd/yyyy h:mm a').includes(query)
              }
              return false
            },
            invitationSent(row, query) {
              if (row.invitationSent) {
                return format(parseISO(row.invitationSent), 'M/dd/yyyy h:mm a').includes(query)
              }
              return false
            }
          },
          columnsClasses: {
            active: 'sm'
          }
        },
        dropdown: [
          { key: '8', value: 'Nucleus User' },
          { key: '11', value: 'Coding Dashboard' },
          { key: '12', value: 'Review Dashboard' },
          { key: '13', value: 'Nucleus User Admin' },
          { key: '3', value: 'Coding Logs' },
          { key: '4', value: 'Coding Schedule' },
          { key: '5', value: 'Review Schedule' }
        ],
        showLicenseDetails: false,
        licenseLoading: false
      }
    },
    name: 'UserAdminList',
    mixins: [HiaAuthorization, hiaFilters],
    components: {
    },
    methods: {
      ...mapActions('userAdmin/', ['GET_USERS', 'GET_LICENSE']),
      initialize() {
        // user admin check
        if (!this.userAdmin()) {
          this.$router.push('/')
          return
        }

        const userClients = []
        this.GET_USERS(userClients).then(() => {
          this.loading = false
        })
      },
      createNewUser() {
        this.$router.push('user-admin/new')
      },
      handleCurrentChange(val) {
        this.currentPage = val
      },
      viewLicense() {
        this.licenseLoading = true
        this.showLicenseDetails = true
        this.GET_LICENSE().then(() => {
          this.licenseLoading = false
        })
      }
    },
    created: function () {
      this.initialize()
    },
    computed: {
      ...mapState('user/', ['currentTenant', 'currentTenantId', 'user', 'clients']),
      ...mapState('userAdmin/', ['users', 'distinctDomains', 'userRoles', 'distinctDomains', 'license']),
      licenseTable() {
        const retTable = []
        const auditor = {
          type: 'Auditor',
          purchased: this.license.numberReviewers,
          used: this.license.numberActiveReviewers,
          available: this.license.numberReviewers - this.license.numberActiveReviewers
        }
        const readOnly = {
          type: 'Read Only',
          purchased: this.license.numberReadOnly,
          used: this.license.numberActiveReadOnly,
          available: this.license.numberReadOnly - this.license.numberActiveReadOnly
        }
        const coders = {
          type: 'Coders',
          purchased: this.license.numberCoders,
          used: this.license.numberActiveCoders,
          available: this.license.numberCoders - this.license.numberActiveCoders
        }
        retTable.push(auditor)
        retTable.push(readOnly)
        retTable.push(coders)

        return retTable
      },
      contractEndDate() {
        return this.license.contractEndDate ? format(parseISO(this.license.contractEndDate), 'M/dd/yyyy') : ''
      }
    },
    watch: {
      '$route'() {
        this.initialize()
      }
    }
  }
</script>

<style scoped>
  #userList>>>.caret-wrapper {
    display: none;
  }

  .coderIcon {
    font-size: 20px;
    float: left;
    height: 12px;
    margin: -3px 5px 0px 0px;
  }

  .licenseIcon {
    font-size: 20px;
    float: left;
    height: 12px;
    margin: -3px 5px 0px 0px;
  }

  >>>.sm {
    width: 75px;
  }
</style>